.toggleButton {
  position: relative;
  right: 40px;
  width: 36px;
  height: 36px;
  overflow: hidden;
  z-index: 2;
}

.toggleButton > span {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: black;
}




  
  .line-1 {
    transform: translate(0, -10px);
  }
  
  .line-2 {
    transform: translate(0, 10px);
  }
  
  .toggleButton[aria-expanded="true"] > .line-1 {
    transform: translate(0, 0) rotate(-45deg);
  }
  
  .toggleButton[aria-expanded="true"] > .line-2 {
    transform: translate(0, 0) rotate(45deg);
  }

  .toggleButton[aria-expanded="true"] > .line-3 {
    display: none;
  }