.wrapper{
  margin: auto;
}
.rogowithtext{
  display: flex;
  justify-content: center;
  position: relative;
  padding-left: 35px;

  .rogobox{
    width: 20%;
    img{
      width: 100%;
    }
  }
}

button {
    appearance: none;
    border: none;
    background: transparent;
    outline: none;
    border-radius: 0;
    letter-spacing: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .header {
    position: fixed;
    top: 0;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    padding:20px 20px 10px;
    z-index: 999;
    background-color: white;
  }

  