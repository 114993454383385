@font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}
    
@font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?subset_id=2&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?subset_id=2&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?subset_id=2&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}
    
@font-face {
    font-family:"stix-two-text";
    src:url("https://use.typekit.net/af/bca480/00000000000000007735caff/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/bca480/00000000000000007735caff/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/bca480/00000000000000007735caff/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}


.p{
    font-family: "futura-pt";
}

.Keyvisual{
    font-family: 'futura', sans-serif;
    margin-top:60px;
        padding-top:0px;

        .backgroundimg{
            background-image:url(../img/keyvisual.jpeg);
            width: 100%;
            height: 55vw;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .caption{
                height: 20vw;
                background-color: white;
                opacity: 0.6;
                text-align: center;
                

                &__title{
                    font-size: 35px;
                    margin:50px 0 0 0; 
                }

                &__sub{
                    font-size: 30px;
                    margin:10px 0 0;
                }
            }
        }

        &__sidetext{
            font-family: "Jacques Francois", serif;
            writing-mode: vertical-lr;
            position: absolute;
            margin: 0;
            top: 280px;
            left: 0;
            font-size:40px;
            font-weight: 100;
        }

        &__imgContainer{
            width: 80vw;
            height: 80vw;
            border-radius: 50%;
            margin: auto;
            box-shadow: inset 0 10px 25px 0 rgba(0, 0, 0, .5);
            background-position: center;
            text-align: center;
            

            &__Caption{
                font-family: "Jacques Francois", serif;
                font-size: 35px;
                font-weight: 100;
                position: relative;
                top: -45px;
            }
        }

        &__snssection{
            position: absolute;
            right: 0;
            top: 600px;
            margin: 0;

            a{
                font-family: "Jacques Francois", serif;
                font-size:40px;
                font-weight: 100;
                writing-mode: vertical-lr;
                margin:0;
                text-decoration: none;
                color: #222;
            }
        }

}

.Catchcolumn{
        text-align: center;
        font-size: 24px;
        max-width: 920px;
        margin: auto;

}

.News{
        background-color: #F7F8F8;
        padding: 40px 0 0;

        &__box{
            max-width: 920px;
            margin: auto;

            .titlebox{
                width: 100px;
                height: 100px;
                background-color: #000;
                margin: auto;
                display: flex;
                
                &__title{
                    color: white;
                    font-size: 21px;
                    margin: auto;
                }
            }

            .contents{
                margin:60px 0 0;

                .contents__box{
                    .caption--board{
                        font-weight: 600;
                    }
                    .underline{
                        margin: 30px 0;
                        height: 1px;
                        background-color: #000;
                    }
                }


                p{
                    margin: 0;
                }
            }
        }
}

.Entry{
        .backgroundimg{
            background-image:url(../img/img-1.jpeg);
            width: 100%;
            height: 55vw;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        
        }

        .entrytitle{
            color:#fff;
            font-size: 35px;
            margin: 0;
        }

        .entrycaption{
            color:#fff;
            font-size: 24px;
        }
}

.flow{
        padding: 60px;
        background-color: #F0F0F8;

        &__box{
            max-width: 920px;
            margin: auto;

            &--blue{
                
            }

            .title{

                display: flex;
                align-items: center;
                justify-content: center;
                gap: 60px;
                margin-bottom: 50px;
    
                &__firstline{
                    height: 1px;
                    background-color: #000;
                    width: 35%;
                }
    
                &__text{
                    font-size: 30px;
                }
    
                &__lastline{
                    height: 1px;
                    background-color: #000;
                    width: 35%;
                }
            }
    
            .contentsbox{
    
                &__titlebox{
                    background-color: #96A2B1;
                    padding: 5px;
                    border-radius: 10px;
                    margin: auto;
                    
    
                    p{
                        margin:0 auto;
                        text-align: center;
                        color: white;
                        font-weight: 800;
                    }

                    &.brown{
                        background-color: #7A6A56;
                    }
                    
                    &.width100{
                        width: 100px;
                    }

                    &.width400{
                        width: 400px;

                    }

                    &.width500{
                        width: 500px;

                    }
                }

                &__textbox{
                    margin:50px 0;
                    border: 1px solid;
                    padding: 30px;
                }
    
            }

            .popup{

                &__button{
                    color:#7A6A56;
                    text-align: center;
                    border: 1px solid #000;
                    border-radius: 20px;
                    font-size: 16px;
                    margin: auto;
                    padding: 10px;
                }
                display: none;

                &.open{
                    display: block;
                    background-image:url(../img/img-5.jpeg);
                    background-position: center;
                    background-color: rgba(255,255,255, 0.4);
                    background-blend-mode: color;
                    padding: 1px;
                    margin-bottom: 60px;

                    .filter{
                        background-color: white;
                        opacity: 0.6;
                        height: 100%;
                        width: 100%;
                    }

                    .contents{
                        background-color: rgba(255,255,255, 0.8);
                        opacity: 0.9;
                        margin: 20px;
                        padding: 20px;
                        
                    }
                }
                &.open2{
                    display: block;
                    background-image:url(../img/img-6.jpeg);
                    background-position: center;
                    background-color: rgba(255,255,255, 0.4);
                    background-blend-mode: color;
                    padding: 1px;
                    margin-bottom: 60px;
                    background-size: cover;

                    .filter{
                        background-color: white;
                        opacity: 0.6;
                        height: 100%;
                        width: 100%;
                    }

                    .contents{
                        background-color: rgba(255,255,255, 0.8);
                        opacity: 0.9;
                        margin: 20px;
                        padding: 20px;
                        
                    }
                }
                &.open3{
                    display: block;
                    background-image:url(../img/img-6.jpeg);
                    background-position: center;
                    background-color: rgba(255,255,255, 0.4);
                    background-blend-mode: color;
                    padding: 1px;
                    margin-bottom: 60px;
                    background-size: cover;

                    .filter{
                        background-color: white;
                        opacity: 0.6;
                        height: 100%;
                        width: 100%;
                    }

                    .contents{
                        background-color: rgba(255,255,255, 0.8);
                        opacity: 0.9;
                        margin: 20px;
                        padding: 20px;
                        
                    }
                }
            }
        }
}

.gaiaEntry{
        .backgroundimg{
            background-image:url(../img/img-2.jpeg);
            height: 30vw;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 20px;
            
        
        }

        .entrytitle{
            font-size: 35px;
            margin: 0;
        }

        .entrycaption{
            font-size: 24px;
        }
}

.gaiaaward{
        max-width: 920px;
        margin: auto;
        text-align: center;
        padding:0 0 80px;

        .title{
            font-size: 30px;
            margin-bottom: 60px;
        }
}

.quadravision{
    text-align: center;
    padding-bottom: 50px;
    padding-top: 70px;

    p{
        margin: 10px auto;
    }
    h3{
        margin: 40px auto;
    }

    .vision{
        p{
            max-width: 700px;
        }
    }
    .value{
        &__title{
            margin-bottom:0;
        }

        &__container{
            margin: auto;
            width: 200px;
        }

        &__cap{
            display: flex;
            justify-content: left;
            align-items: center;

            h3{
                margin: 0;
            }

            p{
                margin: 0;
            }
        }
    }
}

.quadraEntry{
        .backgroundimg{
            background-image:url(../img/img-3.jpg);
            width: 100%;
            height: 55vw;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        
        }

        .entrytitle{
            color:#fff;
            font-size:53px;
            margin: 0;
        }

        .entrycaption{
            color:#fff;
            font-size: 25px;
        }
}

.quadraaward{
    .backgroundimg{
        background-image:url(../img/img-4.jpg);
        width: 100%;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        position: relative;

        &-filter{
            background-color: white;
            opacity: 0.6;
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 1;
        }

        .main{
            z-index: 100;
            padding: 80px 0 50px;

            h3{
                font-size: 25px;
            }

            &__cap{
                margin: 90px auto;
                font-size: 23px;
                font-weight: 600;
            }
        }

        
    }

    .awardbox{
        padding: 30px;
        text-align: left;
        max-width: 780px;
        margin: auto;

        .captitle{
            font-weight:800;
            margin: 0 auto 10px;
            background-color: rgba( 198, 198, 198, 0.6 );
            font-size:16px ;
        }

        p{
            margin: 0 auto 30px;
        }
    }
}

.gallery{
    .backgroundimg{
        background-image:url(../img/img-8.JPEG);
        width: 100%;
        height: 55vw;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items:flex-end;
        padding-bottom: 30px;
    }
}

.company{
    text-align: center;
    padding: 40px 0;
}




@media (max-width: 910px) {
    .Keyvisual{
        padding-top: 0px;

        .backgroundimg{
            background-image:url(../img/keyvisual.jpeg);
            width: 100%;
            height: 100vw;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .caption{
                height: 45vw;
                background-color: white;
                opacity: 0.6;
                text-align: center;
                

                &__title{
                    font-size: 30px;
                    margin:50px 0 0 0; 
                }

                &__sub{
                    font-size: 20px;
                    margin:10px 0 0;
                }
            }
        }

        &__sidetext{
            font-family: "Jacques Francois", serif;
            writing-mode: vertical-lr;
            position: absolute;
            margin: 0;
            top: 280px;
            left: 0;
            font-size:40px;
            font-weight: 100;
        }

        &__imgContainer{
            width: 80vw;
            height: 80vw;
            border-radius: 50%;
            margin: auto;
            box-shadow: inset 0 10px 25px 0 rgba(0, 0, 0, .5);
            background-position: center;
            text-align: center;
            

            &__Caption{
                font-family: "Jacques Francois", serif;
                font-size: 35px;
                font-weight: 100;
                position: relative;
                top: -45px;
            }
        }

        &__snssection{
            position: absolute;
            right: 0;
            top: 600px;
            margin: 0;

            a{
                font-family: "Jacques Francois", serif;
                font-size:40px;
                font-weight: 100;
                writing-mode: vertical-lr;
                margin:0;
                text-decoration: none;
                color: #222;
            }
        }

}

.Catchcolumn{
        text-align: center;
        max-width: 375px;
        margin: auto;
        padding: 0 10px;

        &__title{
            font-size: 20px;
        }

        &__caption{
            font-size: 15px;
        }
}

.News{
        background-color: #F7F8F8;
        padding: 40px 0 0;

        &__box{
            max-width: 375px;
            margin: auto;
            padding: 0 10px;

            .titlebox{
                width: 100px;
                height: 100px;
                background-color: #000;
                margin: auto;
                display: flex;
                
                &__title{
                    color: white;
                    font-size: 21px;
                    margin: auto;
                }
            }

            .contents{
                margin:60px 0 0;

                .contents__box{
                    .caption--board{
                        font-weight: 600;
                    }
                    .underline{
                        margin: 30px 0;
                        height: 1px;
                        background-color: #000;
                    }
                }


                p{
                    margin: 0;
                }
            }
        }
}

.Entry{
        .backgroundimg{
            background-image:url(../img/img-1.jpeg);
            width: 100%;
            height: 100vw;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        
        }

        .entrytitle{
            color:#fff;
            font-size: 35px;
            margin: 0;
        }

        .entrycaption{
            color:#fff;
            font-size: 24px;
        }
}

.flow{
        padding: 60px 1px 60px 1px;
        background-color: #F0F0F8;

        &--blue{
            padding: 60px 1px 60px 1px;
            background-color: #D4D7D7;
        }

        &__box{
            max-width: 375px;
            margin: auto;
            padding: 0 20px;

            .title{

                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                margin-bottom: 50px;
    
                &__firstline{
                    height: 1px;
                    background-color: #000;
                    width: 20%;
                }
    
                &__text{
                    font-size: 20px;
                }
    
                &__lastline{
                    height: 1px;
                    background-color: #000;
                    width: 20%;
                }
            }
    
            .contentsbox{
    
                &__titlebox{
                    background-color: #96A2B1;
                    padding: 5px;
                    border-radius: 10px;
                    
    
                    p{
                        margin:0 auto;
                        text-align: center;
                        color: white;
                        font-weight: 800;
                    }

                    &.brown{
                        background-color: #7A6A56;
                    }
                    
                    &.width100{
                        width: 100px;
                    }

                    &.width400{
                        width: auto;

                    }

                    &.width500{
                        width: auto;

                    }
                }

                &__textbox{
                    margin:50px 20px;
                    border: 1px solid;
                    padding: 30px;
                    background-color: #fff;

                    .popup__button{
                        text-align: center;
                        padding: 10px;
                        font-size:16px;
                    }

                }
    
            }
            .popup{

                &__button{
                    color:#7A6A56;
                    text-align: center;
                    border: 1px solid #000;
                    border-radius: 20px;
                    padding: 10px;
                    font-size:16px;
                }
                display: none;

                &.open{
                    display: block;
                    background-image:url(../img/img-5.jpeg);
                    background-position: center;
                    background-color: rgba(255,255,255, 0.4);
                    background-blend-mode: color;
                    padding: 1px;
                    margin-bottom: 60px;

                    .filter{
                        background-color: white;
                        opacity: 0.6;
                        height: 100%;
                        width: 100%;
                    }

                    .contents{
                        background-color: rgba(255,255,255, 0.8);
                        opacity: 0.9;
                        margin: 20px;
                        padding: 20px;
                        
                    }
                }
                &.open2{
                    display: block;
                    background-image:url(../img/img-6.jpeg);
                    background-position: center;
                    background-color: rgba(255,255,255, 0.4);
                    background-blend-mode: color;
                    padding: 1px;
                    margin-bottom: 60px;
                    background-size: cover;

                    .filter{
                        background-color: white;
                        opacity: 0.6;
                        height: 100%;
                        width: 100%;
                    }

                    .contents{
                        background-color: rgba(255,255,255, 0.8);
                        opacity: 0.9;
                        margin: 20px;
                        padding: 20px;
                        
                    }
                }
                &.open3{
                    display: block;
                    background-image:url(../img/img-6.jpeg);
                    background-position: center;
                    background-color: rgba(255,255,255, 0.4);
                    background-blend-mode: color;
                    padding: 1px;
                    margin-bottom: 60px;
                    background-size: cover;

                    .filter{
                        background-color: white;
                        opacity: 0.6;
                        height: 100%;
                        width: 100%;
                    }

                    .contents{
                        background-color: rgba(255,255,255, 0.8);
                        opacity: 0.9;
                        margin: 20px;
                        padding: 20px;
                        
                    }
                }
            }
        }
}

.gaiaEntry{
        .backgroundimg{
            background-image:url(../img/img-2.jpeg);
            height: 55vw;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        
        }

        .entrytitle{
            font-size: 35px;
            margin: 0;
        }

        .entrycaption{
            font-size: 24px;
        }
}

.gaiaaward{
        max-width: 920px;
        margin: auto;
        text-align: center;
        padding:0 0 80px;

        .title{
            font-size: 30px;
            margin-bottom: 60px;
        }

        p{
            padding: 0 20px;
            text-align: left;
        }
}

.quadravision{
    padding: 80px 0;
    text-align: center;

    .value{
        margin: 40px 0;

        &__cap{
            display: flex;
            justify-content: left;
            align-items: center;
            
            p{
                margin: 0;
            }
        }
        h3{
            margin: 0;
            font-size: 24px;
        }
    }

    .vision{
        margin: 0 40px;
    }
}

.quadraEntry{
        .backgroundimg{
            background-image:url(../img/img-3.jpg);
            width: 100%;
            height: 55vw;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        
        }

        .entrytitle{
            color:#fff;
            font-size: 35px;
            margin: 0;
        }

        .entrycaption{
            color:#fff;
            font-size: 24px;
        }
}

.quadraaward{
    .backgroundimg{
        background-image:url(../img/img-4.jpg);
        width: 100%;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        position: relative;

        &-filter{
            background-color: white;
            opacity: 0.6;
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 1;
        }

        .main{
            z-index: 100;
        }
    }

    .awardbox{
        padding: 30px;
        text-align: left;

        .captitle{
            font-weight:800;
            margin: 0 auto 10px;
        }

        p{
            margin: 0 auto 30px;
        }
    }
}

.company{
    text-align: center;
    padding: 40px 0;
}

}

