.footer{
    background:black;

    .Credit{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        justify-content: center;
        height: 100px;

        &__text{
            color:white;
            
        }
    }
}