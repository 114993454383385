.slidebtn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    color: white; 
    margin-top:24px;
    width: 100px;
    margin:0 auto;
  
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transition: left .4s ease;
      z-index: 0; 
    }
  
    &:hover::before {
      left: 0;
    }
    &:hover p{
      color:black;
    }
  
    & > * {
      position: relative;
      z-index: 1; 
    }

    &__text{
        padding:0;
        margin:0;
    }
  }