.navigation {
    display: none;
    align-items: left;
    flex-direction: column;
    padding: 5rem 2rem;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: white;
    text-align: left;

    a{
      text-decoration: none;

      color: black;
    }
  }
  
  .navigation[aria-hidden="false"] {
    display: flex;
  }
  
  .navigation > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem 0;
  }
  
  .navigation > ul > li {
    text-align: center;
  }